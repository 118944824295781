export const environment = {
  production: true,
  //RUTAS AL SERVIDOR
  //LOCAL
  // API_URL: 'http://localhost:3000',
  // HEROKU: 
  // API_URL: 'https://server-sitio-web-mci.herokuapp.com'
  //DIGITAL OCEAN
  API_URL: 'https://pcoriente.com',

  
  //RUTAS AL DREAMHOST:
  // API_URL: '/servidor'
  // videoHost: 'http://localhost/archivos-mci/videos/',
  videoHost: 'https://www.admin.mcifabricadesogas.com/archivos-mci/videos/'
  
};
