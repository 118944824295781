import { Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ProductosService } from 'app/servicios/productos.service';
// <div class="modal fullscreen-modal fade">
// <div class="modal-dialog">
//   <div class="modal-content">
@Component({
    selector: 'app-modal-content',
    // <div class="modal-content fullscreen-modal modal-dialog">
//     template: `
// <div class="fullscreen-modal" tabindex="-1">
//   <div class="modal-dialog">
//     <div class="modal-content">
//       <div class="modal-header">
//         <h5 class="modal-title">Modal title</h5>
//         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
//           <span aria-hidden="true">&times;</span>
//         </button>
//       </div>
//       <div class="modal-body">
//         <p>Modal body text goes here.</p>
//       </div>
//       <div class="modal-footer">
//         <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
//         <button type="button" class="btn btn-primary">Save changes</button>
//       </div>
//     </div>
//   </div>
// </div>
// `,
    template: `
        <ng-template>
            <div class="modal-header">
                <h5 class="modal-title text-center">
                {{producto.nombreProducto}}
                </h5>
                <button style=" padding: 20px;" type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6 mr-auto ml-auto">
                        <div class="card page-carousel">
                            <ngb-carousel>
                                <ng-template ngbSlide *ngFor="let img of imagenes">
                                    <img src="{{img.url}}" alt="Producto">
                                </ng-template>
                            </ngb-carousel>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-border table-sm">
                                <thead>
                                    <tr>
                                        <th>Pulgadas</th>
                                        <th>Espesor</th>
                                        <th>Largo</th>
                                        <th>Peso</th>
                                        <th>Presentación</th>
                                        <th>Material</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let caracteristica of caracteristicas; let i = index">
                                        <td>{{caracteristica.pulgadas}}</td>
                                        <td>{{caracteristica.espesor}}</td>
                                        <td>{{caracteristica.largo}}</td>
                                        <td>{{caracteristica.peso}}</td>
                                        <td>{{caracteristica.presentacion}}</td>
                                        <td>{{caracteristica.material}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>  
                <div class="row">
                    <div class="col-md-12">
                        <p style="font-size: 18px">{{producto.descripcionProducto}}</p>
                    </div>
                </div>    
            </div>
        </ng-template>
            `
,providers: [
    NgbModalConfig, NgbModal
]
    // , styleUrls: ['./modal-productos.component.scss']
})
export class ContenidoModal {

    @Input() name;
    @Input() id: string = '';
    producto: any = {};
    caracteristicas: any = [];
    imagenes: any = [];

    constructor(
        public activeModal: NgbActiveModal,
        private servicioProductos: ProductosService,
        private config: NgbModalConfig
    ) {
        config.size = 'xl';
     }

    ngOnInit(): void {
        this.obtenerProductos(this.id)
    }

    obtenerProductos = (id) => {
        this.servicioProductos.traeUnProducto(id).subscribe(response => {
            this.producto = response[0];
        });
        this.servicioProductos.getCaracteristicas(id).subscribe(response => {
            this.caracteristicas = response;
        })
        this.servicioProductos.getImagenesProducto(id).subscribe(response => {
            this.imagenes = response;
        });
    }

}

@Component({
    selector: 'app-modal-productos-component',
    templateUrl: './modal-productos.component.html'
})
export class ModalProductosComponent {
    @Input('id') id;
    constructor(private modalService: NgbModal) { }
    open() {
        const modalRef = this.modalService.open(ContenidoModal);
        modalRef.componentInstance.name = 'World';
        modalRef.componentInstance.id = this.id;
    }
}
