import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod'

@Injectable({
  providedIn: 'root'
})
export class DatosEmpresaService {
  API_URI = environment.API_URL;

  constructor(private http: HttpClient) { }

  getDatos() {
    const result = this.http.get(`${this.API_URI}/datos_empresa`)
    return result
  }

  getCorreos() {
    const result = this.http.get(`${this.API_URI}/datos_empresa/correo`)
    return result
  }

  getTelefonos() {
    const result = this.http.get(`${this.API_URI}/datos_empresa/telefono`)
    return result
  }

  obtenerCatalogoPDF() {
    const result = this.http.get(`${this.API_URI}/datos_empresa/catalogo/pdf`)
    return result
  }

}
