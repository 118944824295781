import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod'

@Injectable({
  providedIn: 'root'
})
export class ProductosService {
  API_URI = environment.API_URL;

  constructor(private http: HttpClient) { }

  getProductos() {
    const result = this.http.get(`${this.API_URI}/productos`)
    return result
  }

  traeUnProducto(id: string) {
    const result = this.http.get(`${this.API_URI}/productos/${id}`);
    return result
  }

  getCaracteristicas(id: string) {
    const result = this.http.get(`${this.API_URI}/productos/caracteristicas/${id}`);
    return result
  }

  getImagenesProducto(id: string) {
    const result = this.http.get(`${this.API_URI}/productos/productoimg/${id}`);
    return result
  }

  getProcesos() {
    const result = this.http.get(`${this.API_URI}/procesos`)
    return result
  }

}
