import { Component, OnInit } from '@angular/core';
import { ServiciosEmpresaService } from 'app/servicios/servicios_empresa.service';
import { ProductosService } from 'app/servicios/productos.service';
import { DatosEmpresaService } from 'app/servicios/datos_empresa.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../environments/environment.prod'
// import * as $ from 'jquery';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  providers: [
    NgbModalConfig, NgbModal
  ]
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;

  public VIDEO_HOST = environment.videoHost;

  servicios: any = [];
  productos: any = [];
  datosEmpresa: any = [];
  correos: any = [];
  telefonos: any = [];
  procesos: any = [];

  producto: any = {};
  caracteristicas: any = [];
  imagenes: any = [];
  catalogo: any = {};
  activarIngles: boolean = false;


  constructor(
    private servicios_empresa: ServiciosEmpresaService,
    private servicioProductos: ProductosService,
    private servicioDatosEmpresa: DatosEmpresaService,
    private config: NgbModalConfig, private modalService: NgbModal
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    config.size = 'xl';
  }

  ngOnInit() {
    this.obtenerServicios()
    this.obtenerProductos()
    this.obtenerDatosEmpresa()
    this.obtenerCatalogo()
    this.obtenerProcesos();
  }

  obtenerServicios = () => {
    this.servicios_empresa.getServicios().subscribe(response => {
      this.servicios = response;
    });
  }
  obtenerProductos = () => {
    this.servicioProductos.getProductos().subscribe(response => {
      this.productos = response;
    });
  }
  obtenerDatosEmpresa = () => {
    this.servicioDatosEmpresa.getDatos().subscribe(response => {
      this.datosEmpresa = response[0];
    });
    this.servicioDatosEmpresa.getCorreos().subscribe(response => {
      this.correos = response;
    });
    this.servicioDatosEmpresa.getTelefonos().subscribe(response => {
      this.telefonos = response;
    });
  }
  obtenerCatalogo = () => {
    this.servicioDatosEmpresa.obtenerCatalogoPDF().subscribe(response => {
      this.catalogo = response[0];
    });
  }

  mostrarCatalogo = () => {
    if (this.catalogo) {
      window.open(`${this.catalogo.url}`)
    }
  }

  obtenerProducto = (id) => {
    this.servicioProductos.traeUnProducto(id).subscribe(response => {
      this.producto = response[0];
    });
    this.servicioProductos.getCaracteristicas(id).subscribe(response => {
      this.caracteristicas = response;
    })
    this.servicioProductos.getImagenesProducto(id).subscribe(response => {
      this.imagenes = response;
    });
  }

  open(content, id) {
    this.modalService.open(content);
    console.log(content)
    this.obtenerProducto(id);
  }

  obtenerProcesos = () => {
    this.servicioProductos.getProcesos().subscribe(response => {
      this.procesos = response;
    });
  }

  cambiarIngles = () =>{
    this.activarIngles = true;
  }

  cambiarEspaniol = () =>{
    this.activarIngles = false;
  }

}
